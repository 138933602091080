/* You can add global styles to this file, and also import other style files */
// @import "~@ng-select/ng-select/themes/material.theme.css";
@import '~ngx-bar-rating/themes/br-default-theme';
@import "~@ng-select/ng-select/themes/default.theme.css";

@import 'ngx-toastr/toastr';

.badge {
  width: 100%;
  padding: 5px;
  font-size: 13px;
  max-width: 131px;
}


.api-inline-loader {
  display: block;
  text-align: center;
  margin-top: 30px;
}

.clearfilter {
  margin-left: 16px;
  border-radius: 18px;
  background-color: #fcb92c;
  color: white;
  border: none;
  height: 29px;
  text-align: center;
}

.loader-table {
  overflow: hidden;
}

.bdr,
.select_date .form-control {
  border: 1px dotted black;
  border-style: groove;
  border-color: grey;
  width: 200px;
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: .7875rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  position: relative;
}

.bdr:before,
.select_date .form-control:before {
  position: absolute;
  color: #FBAF02 !important;
  line-height: 28px;
  right: 10px;
  top: 0;
  font-family: remixicon !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\eb1d";
}


.bdr i {
  display: none;
}

.cldicon {
  position: absolute;
  margin-left: 9px;
  color: #FBAF02 !important
}

// .scroller {
//   width: 100%;
//   height: 200px;
//   // overflow-y: scroll;
// }

// /* width */
// ::-webkit-scrollbar {
//   width: 5px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   background: #eee;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: #F5ECDB;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #F5ECDB;
// }

.trnId {
  background-color: #80808099;
  color: white;
  border-radius: 2px;
  text-align: center;
  padding: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;

}

.trnId3 {
  background-color: #13cf6b99;
  color: white;
  border-radius: 2px;
  text-align: center;
  padding: 4px;
}

.trnId2 {
  background-color: #80808099;
  color: white;
  border-radius: 2px;
  text-align: center;
  padding: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 60%;
}

.trnId23 {
  background-color: #80808099;
  color: white;
  border-radius: 2px;
  text-align: center;
  padding: 2px;
  font-size: 12px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: #fcb92c !important;
  border-radius: 2px;
  color: #fff;
  padding: 2px 5px;
  margin: 0 0.4375em 0.4375em 0
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  box-shadow: none !important;
}

.pt table {
  display: block;
  width: 100%;
}

.imageIcons {
  width: 55px;
  height: 55px;
  border-radius: 16px;
}


.image-upload {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 80px;
  height: 60px;
  border-style: ridge;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 15px;
  border-radius: 8px;

}

.upload-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.upload-icon,
.close-icon {
  font-size: 24px;
  color: #ccc;
  cursor: pointer;
}

.uploaded-image {
  // position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.uploaded-image2 {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#image-preview {
  // width: 100%;
  height: 100%;
  object-fit: cover;
}

.close-icon {
  position: absolute;
  top: -4px;
  right: 1px;
  color: #ff0000c9;
}


.mainDiv {
  // padding: 35px;
  display: flex;
  justify-content: flex-end;
}


.table_page a.label-link {
  color: #505d69;
  text-decoration: underline !important;
}

.badge {
  width: 100%;
  padding: 5px;
  font-size: 13px;
  margin: 0;
}

.inv2 {
  width: 25px;
  height: 25px;
  filter: grayscale(100%);
}

.inv1 {
  width: 25px;
  height: 25px;

}

.actionBtn {
  display: flex;
  // justify-content: space-evenly;
}



.table_page a.label-link {
  color: #505d69;
  text-decoration: underline !important;
}

.feature-box {
  position: relative;
  width: 25px;
  height: 25px;
}

.feature-box .feature-input {
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  margin: 0px;
  display: block;
}

.feature-box i {
  font-size: 25px;
  display: block;
  line-height: 25px;
  color: #fbaf02;
}

.feature-box .feature-input:checked+i::before {
  content: "\f124";
}

.feature-box .feature-input:checked+i {
  animation: bell 1s ease-in-out;
}

@keyframes bell {
  0% {
    transform: rotate(35deg);
  }

  12.5% {
    transform: rotate(-30deg);
  }

  25% {
    transform: rotate(25deg);
  }

  37.5% {
    transform: rotate(-20deg);
  }

  50% {
    transform: rotate(15deg);
  }

  62.5% {
    transform: rotate(-10deg)
  }

  75% {
    transform: rotate(5deg)
  }

  100% {
    transform: rotate(0);
  }
}


.radioBtn {
  display: flex;
  justify-content: space-between;
}

.radio-group {
  display: flex;
  flex-direction: unset;
  gap: 23px;
}

.radio-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
}

.radio-label input[type="radio"] {
  margin-right: 8px;
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 50%;
  outline: none;
  transition: background-color 0.2s, border-color 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.radio-label input[type="radio"]::before {
  content: '';
  display: inline-block;
  width: 60%;
  height: 60%;
  background-color: #fff;
  border-radius: 50%;
  transition: background-color 0.2s;
  position: absolute;
}

.radio-label input[type="radio"]:checked {
  border-color: #fcb92c;
}

.radio-label input[type="radio"]:checked::before {
  background-color: #fcb92c;
}

.address-span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 120px;
  display: block;
}


.clearBtn{
  width: 140px;
  height: 31px;
  border-radius: 14px;
  padding: 0px;
  margin-left: 4px;
  background-color: #fcb92c;
  color: white;
  // border-color: papayawhip;

}

.select_date .md-text {
  display: flex;
}

.select_date .primary-date-range-key {
  background: none;
  color: #343a40;
  font: 500 10px Nunito, sans-serif;
  height: 16px;
  letter-spacing: .25px;
  line-height: 16px;
  margin: auto 6px auto auto;
  padding: 0 4px;
}

.select_date .ng-binding {
  color: #343a40;
  font: 500 14px/20px Nunito, sans-serif !important;
  font-weight: 600 !important;
  text-align: right;
  display: flex;
}

.select_date {
  cursor: pointer;
}



.select_date .ng-binding::before {
  display: none;
}
.select_date {
  border: 1px dotted black;
  border-style: groove;
  border-color: grey;
  width: 200px;
  height: calc(1.5em + 0.5rem + 2px);
  // padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  // padding-left: 0.5rem;
  font-size: .7875rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  position: relative;
}
.select_date:before {
  position: absolute;
  color: #FBAF02 !important;
  line-height: 28px;
  right: 10px;
  top: 0;
  font-family: remixicon !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\eb1d";
}
.select_date .ng-binding {
  font-size: 12px !important;
  font-weight: 400 !important;
}
.select_date .ng-binding:after {
  display: none;
}

.dataTables_filter .form-control {
  width: 200px !important;    margin-left: 10px !important; margin-bottom: 10px !important;
}
.dataTables_filter .custom-select.form-control {
  width: 200px;    margin-left: 10px !important; margin-bottom: 10px !important;
}

.table-responsive {
  overflow-x: auto;
}
.dataTables_filter {
  text-align: -webkit-right;
}
/* Add this CSS to your stylesheet */

.popup-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  justify-content: center;
  align-items: center;
  display: flex;
}

.popup-container {
  position: relative;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-in-out;
  background: linear-gradient(45deg, #f2f1f0, #ffffff);
  overflow: hidden; /* Ensures the background gradient doesn't overflow */
  width: 100%;
  max-width: 500px;
  border-style: double;
  
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.popup-title {
  font-size: 20px;
  font-weight: bold;
}

.popup-close-btn {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.popup-content p {
  margin-bottom: 20px;
}

.popup-buttons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;

}

.popup-buttons button {
  padding: 10px 15px;
  margin-right: 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease-in-out;
  width: 122px;
  border-radius: 10px;
  // background-color: #fcb92c;
}

.popup-buttons button:hover {
  background-color: #fcb92c;
  color: #f2f1f0; /* Add a hover effect */
}

.btn-yes {
  background-color: #edbf5c;
  color: #fff;
}

.btn-no {
  background-color: #f44336;
  color: #fff;
}

/* Animation Keyframes */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Apply the animation only when the popup is open */
.popup-overlay.kycModal {
  display: flex;
}


@media (max-width: 769px){
.data-filters label {
  white-space: normal !important;
  text-align: right!important;
}
.data-filters .form-control{width:calc(33.333% - 10px) !important; }
.hide-in-tab{display: none !important;}
.contents {
  width: 100%;
}
.table-responsive .table tbody td {
  width: 100% !important;
}
}

@media (max-width: 479px){
  .dataTables_filter .form-control,
  .dataTables_filter .custom-select.form-control{width:100% !important;   margin-left: 0 !important;}

}

@media (max-width: 769px){
  .dataTables_info {
    text-align: center;
    width: 100%;
}
.paging_simple_numbers {
    display: flex;
    justify-content: center;
  }
  .feature-box {
    width: 100%;
}
.mobile-flex{display: flex !important;
  justify-content: flex-end;}
  }
